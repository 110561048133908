<template>
	<v-layout
		class="btx-search-criteria"
		v-if="search || (tableFilterData && tableFilterData.length > 0)"
	>
		<v-flex md10>
			<h6>Search Criteria</h6>
			<ul>
				<li v-if="search">
					{{ searchString }} have a match for
					<span class="search-text">{{ search }}</span>
				</li>
				<li v-for="(field, index) in tableFilterData" :key="index">
					<span style="text-transform: capitalize" class="mr-1">{{ field.text }}</span>
					<span class="search-text">{{ field.value }}</span>
					<template v-if="tableFilterData[index + 1]"> (and)</template>
				</li>
				<!-- <li v-for="(field, index) in sortedFields" :key="index">
					<span class="search-text">{{ query[field.model] }}</span>
					<template v-if="sortedFields[index + 1]"> (and)</template>
				</li> -->
			</ul>
		</v-flex>
		<v-flex md2 class="text-right">
			<v-btn
				depressed
				color="red lighten-1"
				class="text-white"
				tile
				text
				small
				v-on:click="$emit('close-search', true)"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>
<script>
import { filter } from "lodash";
// import ListingMixin from "@/core/mixins/listing.mixin";
import { mapGetters } from "vuex";

export default {
	data() {
		return {};
	},
	props: {
		searchString: {
			type: String,
			default: null,
		},
		searchFields: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	computed: {
		search() {
			const { search } = this.$route.query;
			return search;
		},
		query() {
			return this.$route.query;
		},
		sortedFields() {
			const _this = this;
			return filter(_this.searchFields, function (row) {
				return _this.query[row.model];
			});
		},
		...mapGetters(["tableFilterData"]),
	},
};
</script>
