<template>
	<v-text-field
		:id="id"
		ref="quantityInput"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rules="rules"
		class="mt-3 pt-0"
		v-model="quantityinput"
		type="number"
		:suffix="suffix"
		maxlength="13"
		:reverse="reverse"
		step="1"
		min="0"
		size="13"
		:append-outer-icon="appendOuterIcon"
		:placeholder="placeholder"
		v-on:click:append-outer="$emit('click:append-outer', true)"
		v-on:change="
			minValue(quantityinput);
			$emit('change', true);
		"
		v-on:keypress="limitDecimal($event, quantityinput)"
		v-on:keydown="$emit('keydown', $event)"
		v-on:keyup="$emit('keyup', $event)"
		v-on:blur="$emit('blur', $event)"
	></v-text-field>
</template>
<script>
export default {
	name: "quantity-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		suffix: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			quantityinput: null,
			input_timeout: null,
		};
	},
	methods: {
		minValue(value) {
			if (value == 0 || !value) {
				clearTimeout(this.input_timeout);
				this.input_timeout = setTimeout(function () {
					this.quantityinput = 0;
				}, 500);
			}
		},
	},
	watch: {
		value() {
			this.quantityinput = this.value;
		},
		quantityinput() {
			this.$emit("input", this.quantityinput);
		},
	},
	mounted() {
		this.quantityinput = this.value;
	},
};
</script>
