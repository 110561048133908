<template>
	<Dialog :dialog="approveDialog" :dialog-width="600">
		<template v-slot:title> Approve {{ type }}</template>
		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<v-icon color="green" size="40">mdi-checkbox-marked-circle</v-icon>
					<span class="svg-icon svg-icon-lg delete-confirm-icon" v-if="false">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
						<!--end::Svg Icon-->
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">Are you sure to approve {{ type }}</p>
				</v-col>
				<v-col v-if="approveNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ approveNote }}</em>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="approveButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="approveButton"
				:disabled="approveButton"
				depressed
				color="green lighten-1"
				tile
				v-on:click="approveSubmit()"
			>
				Yes! Approve
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "mark_as_approved",
		},
		uuid: {
			type: String,
			default: null,
		},
		approveType: {
			type: String,
			default: null,
		},
		approveNote: {
			type: String,
			default: null,
		},
		approveDialog: {
			type: Boolean,
			default: false,
		},
		updateStatus: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			approveButton: false,
		};
	},
	components: {
		Dialog,
	},
	methods: {
		approveSubmit() {
			const _this = this;
			if (this.updateStatus) {
				_this.approveButton = true;
				const payload = {
					action: _this.status,
					selected: [_this.uuid],
					remark: _this.remark ? _this.remark : null,
				};
				ApiService.patch(`${_this.approveType}/bulk-action/status`, payload)
					.then(() => {
						_this.$emit("success");
						_this.$emit("close", true);
						_this.$store.dispatch(SET_LOCAL_DB);
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: `Success ! ${_this.type} has been approved.` },
						]);
					})
					.catch((error) => {
						_this.logError(error);
					})
					.finally(() => {
						_this.approveButton = false;
					});
			} else {
				_this.$emit("success");
			}
		},
	},
};
</script>
