<template>
	<v-navigation-drawer
		class="mail-template-drawer"
		v-model="dialog"
		app
		stateless
		right
		temporary
		width="50%"
	>
		<v-card tile elevation="0">
			<v-card-title
				class="py-1 grey lighten-4 blue--text text--darken-4 text-uppercase border-bottom sticky-on-top"
			>
				<div class="d-flex w-100">
					<div class="h4 m-0 my-auto">{{ title }}</div>
					<v-spacer></v-spacer>
					<v-btn icon tile depresed class="darken-4" v-on:click="$emit('close', true)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>
			<v-card-text class="mb-12">
				<div class="email-template-container py-2">
					<v-form ref="emailApprovalForm" v-model.trim="formValid" lazy-validation>
						<v-row>
							<v-col cols="12" md="2" class="my-auto">
								<label for="email " class="btx-label required">Recipients</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0">
								<div class="d-flex align-items-end">
									<ComboboxInput
										v-model="recipients"
										hide-details
										:items="recipientsList"
										outlined
										placeholder="Recipients"
										dense
										deletable-chips
										small-chips
										:disabled="pageLoading"
										:loading="pageLoading"
										color="blue darken-4"
										multiple
										:rules="[vrules.required(recipients, 'Recipients')]"
										:class="{
											required: !recipients,
										}"
										class="autocomplete-multiple"
										v-on:change="validateEmail($event)"
									></ComboboxInput>
									<v-btn tile depressed color="green white--text" @click="isCC = !isCC"> CC </v-btn>
								</div>
							</v-col>
							<template v-if="isCC">
								<v-col cols="12" md="2" class="my-auto">
									<label for="email " class="btx-label">CC</label>
								</v-col>
								<v-col cols="12" md="10" class="my-auto py-0">
									<ComboboxInput
										v-model="ccRecipients"
										hide-details
										:items="ccRecipientsList"
										outlined
										placeholder="CC"
										dense
										small-chips
										deletable-chips
										color="blue darken-4"
										multiple
										:disabled="pageLoading"
										:loading="pageLoading"
										v-on:change="validateCCEmail($event)"
										class="autocomplete-multiple"
									></ComboboxInput>
								</v-col>
							</template>
							<v-col cols="12" md="2" class="my-auto">
								<label for="subject " class="btx-label required">Subject</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0">
								<TextInput
									hide-details
									id="subject"
									placeholder="Subject"
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="subject"
									:rules="[vrules.required(subject, 'Subject')]"
									:class="{
										required: !subject,
									}"
								>
								</TextInput>
							</v-col>
							<v-col cols="12" md="2" class="mt-3 py-0">
								<label for="message" class="btx-label required">Message</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0 mt-2">
								<ckeditor
									:editor="editor"
									v-model="editorData"
									:config="editorConfig"
									:class="{
										required: !editorData,
									}"
								></ckeditor>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="2" class="py-0 mt-3">
								<label for="message" class="btx-label mt-3">Attachments</label>
							</v-col>
							<v-col cols="12" md="10" class="my-auto py-0 mt-2">
								<FileUpload v-model="files"></FileUpload>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</v-card-text>
			<v-card-actions class="fixed-on-bottom grey lighten-4">
				<v-spacer></v-spacer>
				<v-btn
					@click="sendMail()"
					tile
					depressed
					color="white--text"
					class="blue darken-4 mr-2"
					:disabled="pageLoading"
					:loading="pageLoading"
				>
					<v-icon left size="20">mdi-send</v-icon>
					Send Email
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-navigation-drawer>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import ComboboxInput from "@/view/components/ComboboxInput";
import FileUpload from "@/view/components/FileUpload";
import { SendApprovalMail } from "@/core/lib/common.lib";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { find, map } from "lodash";
export default {
	name: "MailTemplateDrawer",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "Send Email",
		},
		type: {
			type: String,
			default: "",
		},
		defaultEmail: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			pageLoading: false,
			productDrawer: false,
			formValid: true,
			isCC: false,
			recipientsList: [],
			recipients: [],
			ccRecipients: [],
			ccRecipientsList: [],
			files: [],
			subject: null,
			editor: ClassicEditor,
			editorData: "",
			editorConfig: {
				// The configuration of the editor.
			},
		};
	},
	methods: {
		addUnit() {
			this.productCategory.push("");
		},
		deletefield(index) {
			this.productCategory.splice(index, 1);
		},
		async sendMail() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.emailApprovalForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.emailApprovalForm.validate()) {
				return false;
			}
			if (!this.editorData) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: "Message Field is required" }]);
				return false;
			}
			const attachment = map(_this.files, (row) => {
				return { ...row, file: null };
			});
			const payload = {
				uuid: _this.typeUuid,
				emails: _this.recipients,
				cc: _this.ccRecipients,
				subject: _this.subject,
				body: _this.editorData,
				files: attachment,
			};
			_this.pageLoading = true;
			await SendApprovalMail(_this.type, payload);
			_this.$store.commit(SET_MESSAGE, [
				{ model: true, message: "Success ! Mail successfully sent." },
			]);
			_this.pageLoading = false;
			this.$emit("success", true);
			this.$emit("close", true);
		},
		validateEmail(params) {
			if (params.length) {
				const email = params[params.length - 1];
				//eslint-disable-next-line
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const isValid = re.test(String(email).toLowerCase());
				const exists = find(params, (row) => row == email);
				if (!isValid && exists) {
					params.pop();
				}
				this.recipients = params;
			}
		},
		validateCCEmail(params) {
			if (params.length) {
				const email = params[params.length - 1];
				//eslint-disable-next-line
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const isValid = re.test(String(email).toLowerCase());
				const exists = find(params, (row) => row == email);
				if (!isValid && exists) {
					params.pop();
				}
				this.ccRecipients = params;
			}
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		const _member = map(this.localDB("members", []), (row) => row.email);
		this.recipientsList = _member;
		this.ccRecipientsList = _member;
		this.recipients.push(_member[0]);
	},
	components: {
		TextInput,
		ComboboxInput,
		FileUpload,
	},
};
</script>
